<style lang="scss" scoped>
    .demand-sheet-edit {
    }

</style>
<template>

    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont demand-sheet-edit">
                <quotation-order platform-type="2"></quotation-order>
            </div>
        </el-main>
    </div>

</template>

<script type="text/ecmascript-6">
    import quotationOrder from '@/components/quotationOrder/index'

    export default {
        //定义模版数据
        data() {
            return {}
        },
        components: {
            quotationOrder
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted() {


        },
        //定义事件方法
        methods: {},
        //监听模版变量
        watch: {}

    }
</script>

